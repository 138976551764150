<template>
    <NavbarThree/>
       <!-- Hero Area -->

    <div class="hero-area banner-3" id="home">                   
            <div class="hero-area-inner">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 wow fadeInUp animated" data-wow-delay=".2s">
                            <div class="section-title">
                                <h3>{{intro.greeting}} {{intro.name}}</h3>
                                <h1>{{intro.designation}}</h1>                                
                            </div>  
                            <a href="#" class="main-btn curve-btn white">Request Free Call</a>    
                            <div class="banner-three-repeat-grid">
                                <img src="/assets/img/banner-three-repeat-grid.png" alt="">
                            </div>                      
                        </div>  
                        <div class="col-lg-4">
                            <div class="author-three-bg">
                                <img :src="intro.image" alt="">
                            </div>
                            <div class="banner-three-dot-shape">
                                <img src="/assets/img/banner-three-dot-shapes.png" alt="">
                            </div>
                        </div>                      
                    </div>
                </div>                
            </div>            
    </div>

    <!-- About Section -->

    <div id="about" class="about-section about-three section-padding">
        <div class="container">
            <div class="row">
                <div class="col-xl-5 col-lg-5 col-12 mt-50 wow fadeInUp animated" data-wow-delay="100ms">
                    <div class="about-left">
                        <div class="section-title">
                            <h2 class="text-dark">Designing Better Products to enhance Business</h2>
                        </div>
                        <p class="text-gray-2">I create User Interfaces according to solve specific problems. I 
                            specialize in Creative, User-Friendly, and Trending User Interfaces. 
                            I have 5+ years of experience Designing Beautiful, Eye-catchy, and 
                            modern trending websites. If you have any task regard for these 
                            skills, please don't hesitate to contact me. I'll be happy to 
                            participate!</p>
                    </div>
                </div>
                <div class="offset-xl-1 col-xl-5 col-lg-5 col-12">
                    <div class="about-right">
                        <div class="fun-fact-wrap">
                            <div class="single-fact-box clients wow fadeInUp animated" data-wow-delay="100ms">
                                <div class="counter-box">                                    
                                    <p class="counter-number"><span>50</span>K+</p>
                                    <h6>Happy Clients</h6>
                                </div>                                
                                <div class="fun-tag-one">
                                    <img src="/assets/img/fun-tag-1.png" alt="">
                                </div>
                            </div>

                            <div class="single-fact-box projects wow fadeInUp animated" data-wow-delay="200ms">
                                <div class="counter-box">                                    
                                    <p class="counter-number"><span>500</span></p>
                                    <h6>Projects Done</h6>
                                </div>
                                <div class="fun-tag-two">
                                    <img src="/assets/img/fun-tag-2.png" alt="">
                                </div>
                            </div>
                            <div class="single-fact-box problems wow fadeInUp animated" data-wow-delay="300ms">
                                <div class="counter-box">                                    
                                    <p class="counter-number"><span>60</span>K+</p>
                                    <h6>Problems Solved</h6>
                                </div>
                                <div class="fun-tag-three">
                                    <img src="/assets/img/fun-tag-3.png" alt="">
                                </div>
                            </div>
                            <div class="single-fact-box cup_of_tea wow fadeInUp animated" data-wow-delay="400ms">
                                <div class="counter-box">                                    
                                    <p class="counter-number"><span>200</span></p>
                                    <h6>Cup of Tea</h6>
                                </div>
                                <div class="fun-tag-four">
                                    <img src="/assets/img/fun-tag-4.png" alt="">
                                </div>
                                <div class="fun-tag-five">
                                    <img src="/assets/img/fun-tag-5.png" alt="">
                                </div>
                            </div>
                            <div class="fun-tag-six">
                                <img src="/assets/img/fun-tag-6.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
        <div class="about-dot-shape">
            <img src="/assets/img/about-dot-shape.png" alt="">
        </div>
    </div>

    <!-- Specialisation Section  -->

    <div class="specialisation-section section-padding">
        <div class="container">
            <div class="row">
                <div class="col-xl-5 col-lg-5 col-12 wow fadeInDown animated" data-wow-delay="100ms">
                    <div class="author-place-image">
                        <img src="/assets/img/author-place-image.png" alt="">
                        <div class="author-three-img">
                            <img src="/assets/img/author-home-three.png" alt="">
                        </div>
                    </div>                    
                </div>
                <div class="offset-xl-1 col-xl-6 col-lg-6 col-12 mt-50 wow fadeInUp animated" data-wow-delay="100ms">
                    <div class="faq-wrap">
                        <h2 class="text-dark">I am Specialised in</h2>
                        <div class="accordion faqs" id="accordionFaq">
                            <div class="card">
                                <div class="card-header" id="heading1">
                                    <h5 class="mb-0 subtitle">
                                        <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="false" aria-controls="collapse1">
                                            Front-end Development
                                        </button>
                                    </h5>
                                </div>
        
                                <div id="collapse1" class="collapse" aria-labelledby="heading1" data-parent="#accordionFaq">
                                    <div class="card-body">
                                        <div class="content">
                                            <p>I create User Interfaces according to solve specific problems. I 
                                                specialize in Creative, User-Friendly, and Trending User Interfaces. 
                                                I have 5+ years of experience.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="heading2">
                                    <h5 class="mb-0 subtitle">
                                        <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                                            UI/UX Design
                                        </button>
                                    </h5>
                                </div>
        
                                <div id="collapse2" class="collapse" aria-labelledby="heading2" data-parent="#accordionFaq">
                                    <div class="card-body">
                                        <div class="content">
                                            <p>I create User Interfaces according to solve specific problems. I 
                                                specialize in Creative, User-Friendly, and Trending User Interfaces. 
                                                I have 5+ years of experience.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="heading3">
                                    <h5 class="mb-0 subtitle">
                                        <button class="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                                            Digital Marketing
                                        </button>
                                    </h5>
                                </div>
        
                                <div id="collapse3" class="collapse" aria-labelledby="heading3" data-parent="#accordionFaq">
                                    <div class="card-body">
                                        <div class="content">
                                            <p>I create User Interfaces according to solve specific problems. I 
                                                specialize in Creative, User-Friendly, and Trending User Interfaces. 
                                                I have 5+ years of experience.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="heading4">
                                    <h5 class="mb-0 subtitle">
                                        <button class="btn btn-link collapsed active" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="true" aria-controls="collapse2">
                                            Content Marketing
                                        </button>
                                    </h5>
                                </div>
        
                                <div id="collapse4" class="collapse show" aria-labelledby="heading4" data-parent="#accordionFaq">
                                    <div class="card-body">
                                        <div class="content">
                                            <p>I create User Interfaces according to solve specific problems. I 
                                                specialize in Creative, User-Friendly, and Trending User Interfaces. 
                                                I have 5+ years of experience.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Qualification Section  -->

    <div class="qualification-section skill-three section-padding gray-bg">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center">
                    <div class="section-title">                        
                        <h2 class="text-dark">Education & Experience</h2>                            
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div class="experience-area">
                        <div class="qualification-inner">
                            <div class="single-qualification-wrap mb-30 wow fadeInLeft animated" :data-wow-delay="(i+1)*100+'ms'" v-for="(experience,i) in experiences.slice(0,3)" :key="experience.id">
                                <div class="qualify-icon">
                                    <img :src="experience.company_logo" alt="">
                                </div>
                                <div class="qualify-content">
                                    <h4>{{experience.designation}}</h4>
                                    <h5>{{experience.company}} <span>.</span> {{experience.type}}</h5>
                                    <p>{{experience.time_period}}</p>
                                </div>
                            </div>

                                                                                   
                        </div>
                    </div>                    
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div class="education-area">                        
                        <div class="qualification-inner">
                            <div class="single-qualification-wrap white-bg mb-30 wow fadeInLeft animated" :data-wow-delay="(i+1)*100+'ms'" v-for="(education,i) in educations" :key="education.id">
                                <h4>{{education.name}}</h4>
                                <h5>{{education.institute}}</h5>
                                <p>{{education.time_periods}}</p>
                            </div>

                        </div>
                    </div>                    
                </div>
                                
            </div>
        </div>
        <div class="skill-shape-wrap">
            <img src="/assets/img/skill-shape.png" alt="">
        </div>
    </div>

    <!-- Portfolio Section  -->

    <div id="project" class="portfolio-section portfolio-two section-padding">
        <div class="container">
            <div class="row">
                <div class="col-xl-4 col-lg-4 col-12">
                    <div class="section-title">                        
                        <h2 class="text-dark">Portfolio</h2>   
                        <p class="text-dark">Etiam facilisis ligula nec velit posuere egestas. Nunc dictum lectus sem.</p>                         
                    </div>
                </div>
                <div class="col-xl-8 col-lg-8 col-12 d-flex align-items-center justify-content-end">
                    <ul class="port-menu recent text-center">
						<li data-filter="*" class="active">All Works</li>
						<li data-filter=".design">Website</li>
						<li data-filter=".brand">Apps</li>
						<li data-filter=".illustration">Branding</li>
						<li data-filter=".print">illustration</li>
					</ul>
                </div>
            </div>
            <div class="row">
				<div class="col-lg-12">
					<div class="portfolio-list recent">
						
                            <div  :class="'portfolio-bg-wrap '+portfolio.categories"  v-for="portfolio in portfolios" :key="portfolio.id" :style="'background-image:url('+portfolio.background_image+')'">
                                <div class="portfolio-details">
                                    <h4 class="portfolio-title"><a href="#">{{portfolio.name}}</a></h4>
                                    <p>{{portfolio.type}}</p>
                                </div>							
                            </div>   
                        
					</div>
				</div>
			</div>
        </div>
    </div>

    <!-- Pricing Section -->
    <div class="pricing-section section-padding pt-0">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center">
                    <h2 class="text-dark">Pricing Plan</h2>                            
                </div>
            </div>
            <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-6 col-12 mt-60 wow fadeInLeft animated" data-wow-delay="100ms">
                    <div class="single-price-wrap">
                        <div class="price-inner blue-bg-1">
                            <h6>Startup</h6>
                            <h2>$29.00</h2>
                            <p>per month</p>
                        </div>
                        <div class="feature-list-wrap">
                            <ul>
                                <li><i class="las la-check"></i>Basic data furnishing</li>
                                <li><i class="las la-check"></i>Unlimited Bandwidth driven data</li>
                                <li><i class="las la-check"></i>Natural language processing</li>
                                <li><i class="las la-check"></i>Free server domain data</li>
                                <li class="text-gray-3"><i class="las la-check"></i>Free server domain data</li>
                                <li class="text-gray-3"><i class="las la-check"></i>Error free data</li>
                            </ul>
                            <div class="buy-now-btn">
                                <a href="#">Buy Now</a>    
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-12 mt-60 wow fadeInLeft animated" data-wow-delay="200ms">
                    <div class="single-price-wrap active">
                        <div class="price-inner theme-bg">
                            <h6>Agency</h6>
                            <h2>$49.00</h2>
                            <p>per month</p>
                        </div>
                        <div class="feature-list-wrap">
                            <ul>
                                <li><i class="las la-check"></i>Basic data furnishing</li>
                                <li><i class="las la-check"></i>Unlimited Bandwidth driven data</li>
                                <li><i class="las la-check"></i>Natural language processing</li>
                                <li><i class="las la-check"></i>Free server domain data</li>
                                <li><i class="las la-check"></i>Free server domain data</li>
                                <li><i class="las la-check"></i>Error free data</li>
                            </ul>
                            <div class="buy-now-btn">
                                <a href="#">Buy Now</a>    
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-12 mt-60 wow fadeInLeft animated" data-wow-delay="300ms">
                    <div class="single-price-wrap">
                        <div class="price-inner blue-bg-1">
                            <h6>Business</h6>
                            <h2>$79.00</h2>
                            <p>per month</p>
                        </div>
                        <div class="feature-list-wrap">
                            <ul>
                                <li><i class="las la-check"></i>Basic data furnishing</li>
                                <li><i class="las la-check"></i>Unlimited Bandwidth driven data</li>
                                <li><i class="las la-check"></i>Natural language processing</li>
                                <li><i class="las la-check"></i>Free server domain data</li>
                                <li><i class="las la-check"></i>Free server domain data</li>
                                <li><i class="las la-check"></i>Error free data</li>
                            </ul>
                            <div class="buy-now-btn">
                                <a href="#">Buy Now</a>    
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Testimonial Section  -->

    <div class="testimonial-section section-padding testimonial-three gray-bg">
        <div class="container">
            <div class="testimonial-inner gray-bg">
                <div class="row">
                    <div class="col-xl-5 col-lg-6 col-12 d-flex align-items-center justify-content-center">
                        <div class="testimonial-place-img-wrap mt-40">
                            <img src="/assets/img/testimonial-author-place-img.png" alt="">
                            <div class="testimonial-author-img">
                                <img src="/assets/img/testimonial-img-2.png" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-7 col-lg-6 col-12 mt-40 pl-50">
                        <div class="testimonial-icon">
                            <img src="/assets/img/left-quotes-sign.png" alt="">
                        </div>
                        <div class="section-title">
                            <h2 class="text-dark">Client Reviews</h2>                               
                        </div>
                        <ClientReviews/>
                        
                    </div>
                </div>
            </div>
        </div>        
    </div>

    <!-- Blog Section  -->

    <div id="blog" class="blog-section blog-three section-padding">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center">
                    <div class="section-title">
                        <h2 class="text-dark">Latest News</h2>   
                        <h6><router-link class="text-dark" :to="{ name:'Blogs'}">View All Blogs</router-link> </h6>                            
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-6 col-12 wow fadeInLeft animated" :data-wow-delay="(i+1)*100+'ms'" v-for="(blog,i) in blogs.slice(0,3)" :key="blog.id">
                    <div class="single-blog-item">
                        <div class="blog-thumb">
                            <img :src="blog.image" alt="">
                        </div>
                        <div class="blog-content">
                            <div class="blog-meta">
                                <p>{{blog.date}}</p>
                            </div>
                            <div class="blog-title">
                                <h4><router-link :to="{ name:'BlogDetails', params:{id: blog.id} }">{{blog.title}}</router-link></h4>
                            </div>
                        </div>
                    </div>
                </div>

               
                
            </div>
        </div>
    </div>

    <!-- Contact Section  -->

    <div id="contact" class="contact-section contact-two gray-bg section-padding">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center">
                    <div class="section-title">
                        <h6 class="text-gray-2">Contact <span>.....</span> </h6>
                        <h2 class="text-dark">Request for Quote</h2>                               
                    </div>
                </div>
            </div>
            <div class="row mt-60">
                <div class="col-xl-8 col-lg-8 col-md-6 col-12 wow fadeInUp animated" data-wow-delay="100ms">
                    <div class="contact-form-wrap">
                        <div class="form">
                            <div class="row">
                                <div class="col-md-6">
                                    <input type="text" placeholder="Your Full Name">
                                </div>
                                <div class="col-md-6">
                                    <input type="email" placeholder="E-mail Address">
                                </div>
                                <div class="col-12">
                                    <input type="text" placeholder="Subject">
                                </div>
                                <div class="col-12">
                                    <textarea name="message" id="message" cols="30" rows="10" placeholder="Message"></textarea>
                                </div>
                                <div class="col-12">
                                    <input class="main-btn" type="button" value="Send Massage">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-12 wow fadeInRight animated" data-wow-delay="200ms">
                    <div class="map-wrap">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3359.2156438445377!2d-2.2936754376828103!3d53.4626665378156!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487bae72e7e47f69%3A0x6c930e96df4455fe!2sOld%20Trafford!5e0!3m2!1sen!2sbd!4v1661768864802!5m2!1sen!2sbd" width="370" height="400" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer/>
</template>

<script>
// @ is an alias to /src
import NavbarThree from '@/components/Navbar3.vue'
import Footer from '@/components/Footer.vue'
import ClientReviews from '@/components/ClientReviews.vue'

import axios from 'axios'

export default {
  name: 'Home',
  components: {
    NavbarThree,Footer,ClientReviews
  },
  data(){
    return {
        educations : [],
        experiences : [],
        portfolios : [],
        blogs : [],
        intro : [],
        home : []
    }
  },
  async mounted(){
    
    //setting active home as Home3 to local storage
    localStorage.setItem("home","Home3");

    //setting active home as Home3 to vue-store
    this.$store.commit("setHome", "Home3");
    
    //getting active home from vue-store
    this.home = this.$store.state.home;

    //Isotope Filter
	$(".port-menu li").on("click", function () {
		var selector = $(this).attr("data-filter");

		$(".port-menu li").removeClass("active");

		$(this).addClass("active");

		$(".portfolio-list").isotope({
			filter: selector,
			percentPosition: true,
		});
	});
        
    let fetchedEducationData = await axios.get("/assets/data/educations.json");
    this.educations = fetchedEducationData.data.educations;

    let fetchedExperienceData = await axios.get("/assets/data/experiences.json");
    this.experiences = fetchedExperienceData.data.experiences;

    let fetchedPortfolioData = await axios.get("/assets/data/portfolios.json");
    this.portfolios = fetchedPortfolioData.data.portfolios;

    let fetchedBlogsData = await axios.get("/assets/data/blogs.json");
    this.blogs = fetchedBlogsData.data.blogs;

    let fetchedIntroData = await axios.get("/assets/data/home3_intro.json");
    this.intro = fetchedIntroData.data.intro;
  }
}
</script>
