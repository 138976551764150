<template>
    <div v-if="home == 'Home1'" id="header-details" >
       <Navbar/>
    </div>

    <div v-if="home == 'Home2'" id="header-details" >
       <NavbarTwo/>
    </div>

    <div v-if="home == 'Home3'" id="header-details" >
       <NavbarThree/>
    </div>

    <div v-if="home == 'Home4'" id="header-details" >
       <NavbarFour/>
    </div>
    
    
    
    <!-- Breadcrumb Area -->

    <div class="breadcroumb-area gray-bg-2">
        <div class="container">
            <div class="row">
                <div class="col-lg-8">
                    <div class="breadcroumb-title">
                        <h1 class="text-dark">{{currentBlog.title}}</h1>
                        <div class="blog-meta">
                            <p class="text-gray-2">{{currentBlog.date}} <span>Posted By {{currentBlog.author}}</span></p>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>

    <!-- Blog Details  -->

    <div class="single-blog-wrapper section-padding">
        <div class="container">
            <div class="row">
                <div class="col-xl-12">
                    <div class="blog-content-wrap">
                        <p class="text-gray">{{content.para1}}
                        </p>
                        <p class="text-gray">{{content.para2}}                
                        </p>
                        <div class="blog-thumb">
                            <img :src="content.image" alt="">
                        </div>
                        <p class="text-gray">
                           {{content.para3}}
                        </p>
                        <div class="blockquote-area">
                            <p class="text-gray">{{content.quote}}
                            </p>
                            <div class="quote-sign">
                                <img src="/assets/img/blog-left-quotes-sign.png" alt="">
                            </div>
                        </div>
                        <div class="blog-content-one">
                            <h3 class="text-dark">{{content.heading1}}</h3>
                            <p class="text-gray">{{content.heading1_para1}}
                            </p>
                            <p class="text-gray">
                                {{content.heading1_para2}}
                            </p>
                        </div>
                        <div class="blog-list-wrap">
                            <div class="row d-flex align-items-center">
                                <div class="col-xl-6 col-lg-6 col-md-7 col-12">
                                    <h4 class="text-dark">{{content.feature_title}}</h4>
                                    <ul class="blog-list">
                                        <li v-for="item in content.features" :key="item.id"><i class="las la-check"></i>{{item.feature}} </li>
                                                                          
                                    </ul>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-5 col-12">
                                    <div class="list-item-bg">
                                        <img :src="content.feature_image" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="blog-content-two">
                            <h3 class="text-dark">{{content.heading2}}</h3>
                            <p class="text-gray">{{content.heading2_para1}}
                            </p>                            
                        </div>
                        <div class="row d-flex align-items-center mt-50 mb-100">
                            <div class="col-xl-6 col-lg-6 col-md-7 col-12">
                                <div class="blog-tag-wrap">
                                    <ul>
                                        <li v-for="tag in content.tags" :key="tag.id"><a href="#">{{tag.name}}</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-5 col-12 text-end">                               
                                <div class="social-icon-wrap">                        
                                    <span class="text-dark">Share this:</span>
                                    <a class="facebook" href=""><i class="lab la-facebook-f"></i></a>
                                    <a class="dribble" href=""><i class="lab la-instagram"></i></a>
                                    <a class="behance" href=""><i class="lab la-twitter"></i></a>                
                                    <a class="behance" href=""><i class="lab la-linkedin"></i></a>                
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="section-title">
                                    <h2 class="text-dark">Related Post</h2>                               
                                </div>
                            </div>
                        </div>
                        <div class="row gx-5">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-12" v-for="blog in blogs.slice(0,2)" :key="blog.id">
                                <div class="single-blog-item blog-three">
                                    <div class="blog-thumb">
                                        <img :src="blog.image" alt="">
                                    </div>
                                    <div class="blog-content">
                                        <div class="blog-meta">
                                            <p>{{blog.date}}</p>
                                        </div>
                                        <div class="blog-title">
                                            <h4><router-link  :to="{ name:'BlogDetails', params:{id: blog.id} }">{{blog.title}}</router-link></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>

                                                       
                        </div>
                        <div class="comments-section">
                            <h3 class="text-dark">Comments ({{comments.length}}):</h3>
                            
                            <div class="single-comments-wrap" v-for="item in comments" :key="item.id">
                                <div class="author-thumb">
                                    <img :src="item.image" alt="">
                                </div>
                                <div class="comments-inner">
                                    <div class="comments-meta">
                                        <h4 class="author-title text-dark">{{item.name}}</h4>
                                        <p class="comments-date text-gray">{{item.date}}</p>
                                    </div>
                                    <div class="comments-content">
                                        <p class="text-gray">{{item.comment}}</p>
                                    </div>
                                </div>
                                <div class="reply-btn">
                                    <a href="">Reply</a>
                                </div>
                            </div>

                            
                        </div>
                        <div class="contact-form-wrap contact-four">
                            <h3 class="text-dark">Leave A Reply</h3>
                            <div class="form">
                                <div class="row">
                                    <div class="col-md-6">
                                        <input type="text" placeholder="Your Full Name">
                                    </div>
                                    <div class="col-md-6">
                                        <input type="email" placeholder="E-mail Address">
                                    </div>
                                    <div class="col-12">
                                        <input type="text" placeholder="Subject">
                                    </div>
                                    <div class="col-12">
                                        <textarea name="message" id="message" cols="30" rows="10" placeholder="Message"></textarea>
                                    </div>
                                    <div class="col-12">
                                        <input class="main-btn" type="button" value="Post Comment">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer/>
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import NavbarTwo from '@/components/Navbar2.vue'
import NavbarThree from '@/components/Navbar3.vue'
import NavbarFour from '@/components/Navbar4.vue'
import Footer from '@/components/Footer.vue'

import axios from 'axios'

export default {
  name: 'Home',
  components: {
    Navbar,NavbarTwo,NavbarThree,NavbarFour,Footer
  },
  data(){
    return {
        blogs : [],
        currentBlog : [],
        content : [],
        comments : [],
        home : ''
    }
  },

  async mounted(){
    //scrolling to top of the window
    window.$('body,html').animate({scrollTop: stop}, 1000);
    window.scrollTo(0, 0);

    //preloader
    document.querySelector('.preloader-two').style.display = '';
    setTimeout(()=>{
        document.querySelector('.preloader-two').style.display = 'none';
    },1000);
    
   //removing active class from all nav link and adding active class to clicked nav link
    setTimeout(() => {
        this.removeActiveClass();
        document.getElementById('blog-nav').classList.add('active');
    }, 500);
    


    //getting active home from vue-store
    this.home = this.$store.state.home;
    

    //Isotope Filter
	$(".port-menu li").on("click", function () {
		var selector = $(this).attr("data-filter");

		$(".port-menu li").removeClass("active");

		$(this).addClass("active");

		$(".portfolio-list").isotope({
			filter: selector,
			percentPosition: true,
		});
	});

    //sticky nav
    $(window).scroll(function(){
            if ($(this).scrollTop() > 90) {
            $('#header-details').addClass('sticky-top');
            } else {
            $('#header-details').removeClass('sticky-top');
            }
        });

    let fetchedBlogsData = await axios.get("/assets/data/blogs.json");
    this.blogs = fetchedBlogsData.data.blogs;
   

    this.currentBlog = this.blogs.find((item) => item.id == this.$route.params.id);
    this.content = this.currentBlog.content;
    this.comments = this.currentBlog.comments;

  },
methods:{
    removeActiveClass(){
         var elems = document.querySelectorAll(".nav-link");

        elems.forEach(function(el) {
            el.classList.remove("active");
        });
    }
  }
}
</script>
<style scoped>
.sticky-top{
    position: sticky;
    /* background: #F5F5F5; */
    top: 0;
    width: 100%;
    z-index: 99;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.nav1{
    background: #1D1730 !important;
}
.nav2{
    background: #FEF6F6 !important;
}
.nav3{
    background: #6838DC !important;
}
.nav4{
    background: #F5F5F5 !important;
}
</style>