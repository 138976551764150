<template>
    <div v-if="home == 'Home1'" id="header-details" >
       <Navbar/>
    </div>

    <div v-if="home == 'Home2'" id="header-details" >
       <NavbarTwo/>
    </div>

    <div v-if="home == 'Home3'" id="header-details" >
       <NavbarThree/>
    </div>

    <div v-if="home == 'Home4'" id="header-details" >
       <NavbarFour/>
    </div>
   <!-- Breadcrumb Area -->

    <div class="breadcroumb-area gray-bg-2">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcroumb-title">
                        <h1 class="text-dark">Blog</h1>
                        <h6 class="text-gray"><router-link :to="{name:'Home2'}" >Home</router-link> / Blog</h6>
                    </div>
                </div>
            </div>
            <div class="bread-ellipse">
                <img src="/assets/img/breadcrumb-ellipse.png" alt="">
            </div>
            <div class="bread-shape">
                <img src="/assets/img/breadcrumb-shape.png" alt="">
            </div>
        </div>
    </div>

    <!-- Blog -->

    <div id="blog" class="blog-section blog-three section-padding">
        <div class="container">            
            <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-6 col-12" v-for="blog in blogs" :key="blog.id">
                    <div class="single-blog-item">
                        <div class="blog-thumb">
                            <img :src="blog.image" alt="">
                        </div>
                        <div class="blog-content">
                            <div class="blog-meta">
                                <p>{{blog.date}}</p>
                            </div>
                            <div class="blog-title">
                                <h4><router-link :to="{ name:'BlogDetails', params:{id: blog.id} }">{{blog.title}}</router-link></h4>
                            </div>
                        </div>
                    </div>
                </div>

               
               
                
            </div>
        </div>
    </div>
    <Footer/>
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import NavbarTwo from '@/components/Navbar2.vue'
import NavbarThree from '@/components/Navbar3.vue'
import NavbarFour from '@/components/Navbar4.vue'
import Footer from '@/components/Footer.vue'

import axios from 'axios'

export default {
    name: 'Home',
    components: {
        Navbar,NavbarTwo,NavbarThree,NavbarFour,Footer
    },

    data(){
        return {
            blogs : [],
            home : ''
        }
    },
  async mounted(){
    //scrolling to top of the window
    window.$('body,html').animate({scrollTop: stop}, 1000);
    window.scrollTo(0, 0);

    //preloader
    document.querySelector('.preloader-two').style.display = '';
    setTimeout(()=>{
        document.querySelector('.preloader-two').style.display = 'none';
    },1000);
   
   //removing active class from all nav link and adding active class to clicked nav link
   setTimeout(() => {
        this.removeActiveClass();
        document.getElementById('blog-nav').classList.add('active');
    }, 500);
    


    //getting the active home
    this.home = this.$store.state.home;
   

    //Isotope Filter
	$(".port-menu li").on("click", function () {
		var selector = $(this).attr("data-filter");

		$(".port-menu li").removeClass("active");

		$(this).addClass("active");

		$(".portfolio-list").isotope({
			filter: selector,
			percentPosition: true,
		});
	});

    //sticky nav
    $(window).scroll(function(){
        if ($(this).scrollTop() > 90) {
        $('#header-details').addClass('sticky-top');
        } else {
        $('#header-details').removeClass('sticky-top');
        }
    });

    let fetchedBlogsData = await axios.get("/assets/data/blogs.json");
    this.blogs = fetchedBlogsData.data.blogs;

  },

  methods: {
    
    removeActiveClass(){
        var elems = document.querySelectorAll(".nav-link");

        elems.forEach(function(el) {
            el.classList.remove("active");
        });
    }
  }
}
</script>
<style scoped>
.sticky-top{
    position: sticky;
    /* background: #FEF6F6; */
    top: 0;
    width: 100%;
    z-index: 99;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}
.nav1{
    background: #1D1730 !important;
}
.nav2{
    background: #FEF6F6 !important;
}
.nav3{
    background: #6838DC !important;
}
.nav4{
    background: #F5F5F5 !important;
}
</style>