<template>
    <Navbar/>
       <!-- Hero Area -->

    <div class="hero-area dark-bg-1" id="home">                   
            <div class="hero-area-inner">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 wow fadeInUp animated" data-wow-delay=".2s">
                            <div class="section-title">
                                <h3>{{intro.greeting}}</h3>
                                <h1>{{intro.name}}</h1>
                                <p>{{intro.designation}}</p>
                            </div>
                            <a href="#" class="main-btn">Download Resume</a>
                            <a href="#contact" class="main-btn dark-btn">Contact Me</a>
                            <div class="social-area-wrap">
                                <p>Find Me On</p>
                                <a href=""><i class="lab la-facebook-f"></i></a>
                                <a href=""><i class="lab la-dribbble"></i></a>
                                <a href=""><i class="lab la-behance"></i></a>
                                <a href=""><i class="lab la-instagram"></i></a>                                
                            </div>
                            
                        </div>
                        <div class="col-lg-6">
                            <div class="hero-author-img">
                                <img :src="intro.image" alt="">
                            </div>
                            <div class="shape-wrap">
                                <div class="shape-inner">
                                    <div class="ellipse-1">
                                        <img src="/assets/img/ellipse-1.png" alt="">
                                    </div>
                                    <div class="ellipse-2">
                                        <img src="/assets/img/ellipse-2.png" alt="">
                                    </div>
                                    <div class="ellipse-3">
                                        <img src="/assets/img/ellipse-3.png" alt="">
                                    </div>
                                </div>
                                <div class="shape-dot-wrap">
                                    <img src="/assets/img/dot-shapes.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="banner-shape-wrap">
                    <div class="banner-shape-inner">
                        <img src="/assets/img/shape/shaps1.png" alt="" class="shape shape1 rotate3d">
                        <img src="/assets/img/shape/shaps2.png" alt="" class="shape shape2 rotate3d">
                        <img src="/assets/img/shape/shaps4.png" alt="" class="shape shape4 rotate3d">
                        <img src="/assets/img/shape/shaps5.png" alt="" class="shape shape5 rotate2d">
                        <img src="/assets/img/shape/shaps3.png" alt="" class="shape shape3 rotate2d">
                    </div>
                </div>
            </div>
            
            <div class="shape-left-wrap">
                <img src="/assets/img/Group 19.png" alt="">
            </div>

            <div class="shape-right-wrap">
                <img src="/assets/img/Path 18.png" alt="">
            </div>

            
    </div>

    <!-- Fun Fact Section  -->

    <div class="fun-fact-area section-padding dark-bg-2">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-6 wow fadeInLeft" data-wow-delay=".2s">
                    <div class="single-counter-box">
                        <div class="counter-box-inner">
                            <div class="fact-icon">
                                <img src="/assets/img/fact-icon/trophy.png" alt="">
                            </div>                        
                            <p>Award</p>
                            <h6>Wining Designer</h6>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 wow fadeInLeft" data-wow-delay=".4s">
                    <div class="single-counter-box">
                        <div class="counter-box-inner">
                            <div class="fact-icon">
                                <img src="/assets/img/fact-icon/rocket.png" alt="">
                            </div>
                            <p class="counter-number"><span>10</span>K+</p>
                            <h6>Project Completed</h6>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 wow fadeInLeft" data-wow-delay=".6s">
                    <div class="single-counter-box">
                        <div class="counter-box-inner">
                            <div class="fact-icon">
                                <img src="/assets/img/fact-icon/user.png" alt="">
                            </div>
                            <p class="counter-number"><span>50</span>M+</p>
                            <h6>Happy Clients</h6>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 wow fadeInLeft" data-wow-delay=".8s">
                    <div class="single-counter-box">
                        <div class="counter-box-inner">
                            <div class="fact-icon">
                                <img src="/assets/img/fact-icon/coffee-cup.png" alt="">
                            </div>
                            <p class="counter-number"><span>5</span>k+</p>
                            <h6>Cup of Tea</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="funfact-circle-wrap">
            <img src="/assets/img/fun-fact-circle.png" alt="">
        </div>
    </div>

    <!-- About Section -->

    <div id="about" class="about-section about-one section-padding dark-bg-1">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-12 wow fadeInUp animated" data-wow-delay="100ms">
                    <div class="about-left">
                        <div class="repeat-grid">
                            <img :src="about.image" alt="">
                        </div>
                        <div class="section-title">
                            <h6>About Me <span>.....</span> </h6>
                            <h2>Awarded Designer</h2>                            
                        </div>
                        <p>{{about.description}}                            
                        </p>
                        <p>{{about.description_two}}</p>                        
                            <div class="about-btn">
                                <a href="#about" class="main-btn">Know More</a>
                            </div>
                            <div class="pop-up-video">
                                <a href="https://www.youtube.com/watch?v=yFwGpiCs3ss" class="video-play-btn mfp-iframe">
                                    <i class="las la-play"></i> <span>Watch Video</span>
                                </a>
                            </div>                        
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-12 wow fadeInDown animated" data-wow-delay="100ms">
                    <div class="about-right">
                        <div class="about-img-wrap">
                            <img src="/assets/img/about-shape.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="about-section-shape">
            <img src="/assets/img/Group 51.png" alt="">
        </div>
        <div class="about-dot-shape">
            <img src="/assets/img/about-dot-shape.png" alt="">
        </div>
    </div>

    <!-- Qualification Section  -->

    <div class="qualification-section section-padding dark-bg-2">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center">
                    <div class="section-title">
                        <h6>Qualification <span>.....</span> </h6>
                        <h2>Skill & Experience</h2>                            
                    </div>
                </div>
            </div>
            <div class="row mt-70">
                <div class="col-xl-4 col-lg-4 col-md-6 col-12 wow fadeInLeft animated" data-wow-delay="100ms">
                    <div class="education-area">
                        <h3>Education <span>.....</span></h3>
                        <div class="qualification-inner">
                            <div class="single-qualification-wrap dark-bg-1 mb-30 wow fadeInUp animated" :data-wow-delay="(i+1)*100+'ms'" v-for="(education,i) in educations" :key="education.id">
                                <h4>{{education.name}}</h4>
                                <h5>{{education.institute}}</h5>
                                <p>{{education.time_periods}}</p>
                            </div>
                            
                        </div>
                    </div>                    
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-12 wow fadeInLeft animated" data-wow-delay="200ms">
                    <div class="experience-area">
                        <h3>Experience <span>.....</span></h3>
                        <div class="qualification-inner">
                            <div class="single-qualification-wrap dark-bg-1 mb-30 wow fadeInUp animated" :data-wow-delay="(i+1)*100+'ms'" v-for="(experience,i) in experiences" :key="experience.id">
                                <div class="qualify-icon">
                                    <img :src="experience.company_logo" alt="">
                                </div>
                                <div class="qualify-content">
                                    <h4>{{experience.designation}}</h4>
                                    <h5>{{experience.company}} <span>.</span> {{experience.type}}</h5>
                                    <p>{{experience.time_period}}</p>
                                </div>
                            </div>
                            
                           
                            
                        </div>
                    </div>                    
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-12 wow fadeInLeft animated" data-wow-delay="300ms">
                    <div class="skill-area">
                        <h3>My Skills <span>.....</span></h3>
                        <div class="skill-inner dark-bg-1 mb-30">
                            <ul class="skill-item">
                                <li v-for="skill in skills" :key="skill.id">{{skill.name}}</li>
                                
                            </ul>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
        <div class="skill-shape-wrap">
            <img src="/assets/img/skill-shape.png" alt="">
        </div>
    </div>

    <!-- Service Section  -->

    <div id="services" class="service-section section-padding dark-bg-1">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center">
                    <div class="section-title">
                        <h6>Services <span>.....</span> </h6>
                        <h2>What I Offer</h2>                            
                    </div>
                </div>
            </div>
            <div class="row gx-5">
                <div class="col-xl-4 col-lg-4 col-md-6 col-12 wow fadeInLeft animated" :data-wow-delay="(i+1)*100+'ms'" v-for="(service,i) in services" :key="service.id">
                    <div class="single-service-item dark-bg-2">
                        <div class="service-inner">
                            <div class="service-icon">
                                <img :src="service.icon" alt="">
                            </div>
                            <div class="service-content">
                                <h4>{{service.name}}</h4>
                                <p>{{service.description}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Portfolio Section  -->

    <div id="project" class="portfolio-section section-padding dark-bg-2">
        <div class="container">
            <div class="row">
                <div class="col-xl-4 col-lg-4 col-12">
                    <div class="section-title">
                        <h6>Projects <span>.....</span> </h6>
                        <h2>Portfolio</h2>   
                        <p>Etiam facilisis ligula nec velit posuere egestas. Nunc dictum lectus sem.</p>                         
                    </div>
                </div>
                <div class="col-xl-8 col-lg-8 col-12 d-flex align-items-center justify-content-end">
                    <ul class="port-menu recent text-center">
						<li data-filter="*" class="active">All Works</li>
						<li data-filter=".design">Website</li>
						<li data-filter=".brand">Apps</li>
						<li data-filter=".illustration">Branding</li>
						<li data-filter=".print">illustration</li>
					</ul>
                </div>
            </div>
            <div class="row">
				<div class="col-lg-12">
					<div class="portfolio-list recent">
						
                            <div  :class="'portfolio-bg-wrap '+portfolio.categories"  v-for="portfolio in portfolios" :key="portfolio.id" :style="'background-image:url('+portfolio.background_image+')'">
                                <div class="portfolio-details">
                                    <h4 class="portfolio-title"><a href="#">{{portfolio.name}}</a></h4>
                                    <p>{{portfolio.type}}</p>
                                </div>							
                            </div>                          
					</div>
				</div>
			</div>
        </div>
    </div>

    <!-- Testimonial Section  -->

    <div class="testimonial-section dark-bg-2">
        <div class="container">
            <div class="testimonial-inner light-bg-1 wow fadeInUp animated" data-wow-delay="100ms">
                <div class="row gx-1">
                    <div class="col-xl-6 col-lg-6 col-12">
                        <div class="testimonial-img">
                            <img src="/assets/img/testimonial-shapes.png" alt="">
                            <div class="testimonial-author-img">
                                <img src="/assets/img/testimonial-img-1.png" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-12 mt-50 pl-50">
                        <div class="testimonial-icon">
                            <img src="/assets/img/left-quotes-sign.png" alt="">
                        </div>
                        <div class="section-title">
                            <h6 class="text-black">Testimonials <span>.....</span> </h6>
                            <h2 class="text-black">Client Reviews</h2>                               
                        </div>
                        <ClientReviews/>
                        
                    </div>
                </div>
            </div>
        </div>
        <div class="testimonial-dot-shape">
            <img src="/assets/img/testimonial-shape.png" alt="">
        </div>
    </div>

    <!-- Blog Section  -->

    <div id="blog" class="blog-section section-padding dark-bg-1">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center">
                    <div class="section-title">
                        <h6>Blog <span>.....</span> </h6>
                        <h2>Latest News</h2>
                        <h6><router-link :to="{ name:'BlogsDark'}"><span>View All Blogs</span></router-link> </h6>                            
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-6 col-12 wow fadeInLeft animated" :data-wow-delay="(i+1)*100+'ms'" v-for="(blog,i) in blogs.slice(0,3)" :key="blog.id">
                    <div class="single-blog-item">
                        <div class="blog-thumb">
                            <img :src="blog.image" alt="">
                        </div>
                        <div class="blog-content">
                            <div class="blog-meta">
                                <p>{{blog.date}}</p>
                            </div>
                            <div class="blog-title">
                                <h4><router-link :to="{ name:'BlogDetailsDark', params:{id: blog.id} }">{{blog.title}}</router-link></h4>
                            </div>
                        </div>
                    </div>
                </div>


               
                
            </div>
        </div>
    </div>

    <!-- Contact Section  -->

    <div id="contact" class="contact-section section-padding dark-bg-2">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center">
                    <div class="section-title">
                        <h6>Contact <span>.....</span> </h6>
                        <h2>Request for Quote</h2>                               
                    </div>
                </div>
            </div>
            <div class="row mt-60">
                <div class="col-xl-8 col-lg-8 col-md-6 col-12 wow fadeInUp animated" data-wow-delay="100ms">
                    <div class="contact-form-wrap">
                        <div class="form">
                            <div class="row">
                                <div class="col-md-6">
                                    <input type="text" placeholder="Your Full Name">
                                </div>
                                <div class="col-md-6">
                                    <input type="email" placeholder="E-mail Address">
                                </div>
                                <div class="col-12">
                                    <input type="text" placeholder="Subject">
                                </div>
                                <div class="col-12">
                                    <textarea name="message" id="message" cols="30" rows="10" placeholder="Message"></textarea>
                                </div>
                                <div class="col-12">
                                    <input class="main-btn" type="button" value="Send Massage">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-12 wow fadeInRight animated" data-wow-delay="200ms">
                    <div class="map-wrap">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3359.2156438445377!2d-2.2936754376828103!3d53.4626665378156!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487bae72e7e47f69%3A0x6c930e96df4455fe!2sOld%20Trafford!5e0!3m2!1sen!2sbd!4v1661768864802!5m2!1sen!2sbd" width="370" height="400" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer/>
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import ClientReviews from '@/components/ClientReviews.vue'

import axios from 'axios'

export default {
  name: 'Home',
  components: {
    Navbar,Footer,ClientReviews
  },
  data(){
    return {
        services : [],
        educations : [],
        skills : [],
        experiences : [],
        portfolios : [],
        about : [],
        blogs : [],
        intro : [],
        home : ''
    }
  },
  async mounted(){

    //setting active home as Home1 to local storage
    localStorage.setItem("home","Home1");

    //setting active home as Home1 to vue-store
    this.$store.commit("setHome", "Home1");

    //getting active home from vue-store
    this.home = this.$store.state.home;
    
    //Isotope Filter
	$(".port-menu li").on("click", function () {
		var selector = $(this).attr("data-filter");

		$(".port-menu li").removeClass("active");

		$(this).addClass("active");

		$(".portfolio-list").isotope({
			filter: selector,
			percentPosition: true,
		});
	});
       
    let fetchedData = await axios.get("/assets/data/services.json");
    this.services = fetchedData.data.services;

    let fetchedEducationData = await axios.get("/assets/data/educations.json");
    this.educations = fetchedEducationData.data.educations;

    let fetchedSkillData = await axios.get("/assets/data/skills.json");
    this.skills = fetchedSkillData.data.skills;

    let fetchedExperienceData = await axios.get("/assets/data/experiences.json");
    this.experiences = fetchedExperienceData.data.experiences;

    let fetchedPortfolioData = await axios.get("/assets/data/portfolios.json");
    this.portfolios = fetchedPortfolioData.data.portfolios;

    let fetchedAboutData = await axios.get("/assets/data/about.json");
    this.about = fetchedAboutData.data.about;

    let fetchedBlogsData = await axios.get("/assets/data/blogs.json");
    this.blogs = fetchedBlogsData.data.blogs;

    let fetchedIntroData = await axios.get("/assets/data/home1_intro.json");
    this.intro = fetchedIntroData.data.intro;
    
  }
}
</script>
