<template>
   <!-- Header Area -->
    
    <div id="header-2" class="header-area absolute-header nav4">
        <div class="sticky-area">
            <div class="navigation">
                <div class="container">
                    <div class="header-inner-box">
                        <div class="logo">
                            <router-link class="navbar-brand" :to="{ name: 'Home4'}"  @click="divScroller('home','home-nav')"><img src="/assets/img/logo.png" alt="" /></router-link>
                        </div>

                        <div class="main-menu">
                            <nav class="navbar navbar-expand-lg">
                                <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                    aria-expanded="false" aria-label="Toggle navigation">
                                    <span class="navbar-toggler-icon"></span>
                                    <span class="navbar-toggler-icon"></span>
                                    <span class="navbar-toggler-icon"></span>
                                </button>

                                <div class="collapse navbar-collapse justify-content-center"
                                    id="navbarSupportedContent">
                                    <ul class="navbar-nav m-auto">
                                        
                                        <li class="nav-item smooth-menu">
                                            <router-link :to="{ name: 'Home4'}" id="home-nav" class="nav-link active" @click="divScroller('home','home-nav')" >Home
                                                <span class="sub-nav-toggler"> </span>
                                            </router-link>                                            
                                        </li>

                                        <li class="nav-item smooth-menu">
                                            <a class="nav-link" id="about-nav" @click="divScroller('about','about-nav')" >About</a>
                                        </li>
                                        <li class="nav-item smooth-menu">
                                            <a class="nav-link" id="services-nav" @click="divScroller('services','services-nav')" >Services</a>
                                        </li>
                                        <li class="nav-item smooth-menu">
                                            <a class="nav-link" id="project-nav" @click="divScroller('project','project-nav')" >Projects</a>
                                        </li>
                                        <li class="nav-item smooth-menu">
                                            <a class="nav-link" id="blog-nav" @click="divScroller('blog','blog-nav')" >Blog</a>
                                        </li>
                                        <li class="nav-item smooth-menu">
                                            <a class="nav-link" id="contact-nav" @click="divScroller('contact','contact-nav')">Contact</a>
                                        </li>                                       
                                    </ul>
                                </div>
                            </nav>
                        </div>

                        <div class="header-btn">
                            <a @click="divScroller('contact','contact-nav')" class="main-btn primary">Hire Me</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Navbar',
    mounted(){
        
        /**
         *  navbar sticky effect
         */
        $(window).scroll(function(){
            if ($(this).scrollTop() > 25) {
            $('#header-2').addClass('sticky-top');
            } else {
            $('#header-2').removeClass('sticky-top');
            }
        });
    },

    methods:{
        /**
         *  scrolls to specific navbar item section
         */        
        divScroller(divId,elId){

            this.removeActiveClass();    

            //checking if the router-link is home page or not
            if(
                this.$route.name == "BlogDetails" ||
                this.$route.name == "BlogDetailsDark" ||
                this.$route.name == "Blogs" ||
                this.$route.name == "BlogsDark" 

             ){
                //redirecting to home page
                this.$router.push({name: this.$store.state.home});
              
                setTimeout(() => {
                    this.removeActiveClass();
                    
                    //adding active class to clicked nav link
                    document.getElementById(elId).classList.add('active');
                    document.getElementById(divId).scrollIntoView();                 
                    
                }, 500);                
                
             }else{
                
                    //adding active class to clicked nav link
                    document.getElementById(elId).classList.add('active');
                    document.getElementById(divId).scrollIntoView();   
             }
            
        },

        /**
         *  removes active class from all nav link
         */
        removeActiveClass(){
            var elems = document.querySelectorAll(".nav-link");

            elems.forEach(function(el) {
                el.classList.remove("active");
            });
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sticky-top{
    position: sticky;
    background: #F5F5F5;
    top: 0;
    width: 100%;
    z-index: 99;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}
</style>

